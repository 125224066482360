import tokens from './tokens'
import { VaultConfig } from './types'

const vaults: VaultConfig[] = [  
{  
    pid: 2,
    lpSymbol: 'SIRIUS',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/swap?outputCurrency=0xB1289f48E8d8Ad1532e83A8961f6E8b5a134661D',
	strategyAddress:{
		137: '0xf36588739784912085a0d4E7CF666c4f9B4f9178',
		80001: '',
	},
    lpAddresses: {
      137: '0xb1289f48e8d8ad1532e83a8961f6e8b5a134661d',
      80001: '',
    },
	masterChefAddress:{
		137: '0xD0023db30D1f4dB77e1049E79817B4D5dc571d15',
		80001: '',
	},
	spid: 0,
    token: tokens.sirius,
    quoteToken: tokens.sirius,
	rewardToken: tokens.sirius,
	emissionFunctionName: 'siriusPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Polysky',
  }, 
  {
    pid: 1,
    lpSymbol: 'QUICK - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x9Bbcda2606e616659b118399A2823E8a392f55DA',
	strategyAddress:{
		137: '0x6d2f82A4D90c865B8A118d47677A1d6d6B85b1e3',
		80001: '',
	},
    lpAddresses: {
      137: '0x1f1e4c845183ef6d50e9609f16f6f9cae43bc9cb',
      80001: '',
    },
	masterChefAddress:{
		137: '0x939290ed45514e82900ba767bbcfa38ee1067039',
		80001: '',
	},
	spid: 0,
    token: tokens.quick,
    quoteToken: tokens.usdc,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:26,
	platform: 'Quickswap',
  },
  {
    pid: 117,
    lpSymbol: 'GENESIS - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x51869836681bce74a514625c856afb697a013797/0x831753dd7087cac61ab5644b308642cc1c33dc13',
	strategyAddress:{
		137: '0x02dc450caba03b233ffc0dbb91391ef108f4e0e8',
		80001: '',
	},
    lpAddresses: {
      137: '0xf0696be85fa54f7a8c9f20aa98aa4409cd5c9d1b',
      80001: '',
    },
	masterChefAddress:{
		137: '0x3620418dd43853c35ff8df90cab5508fb5df46bf',
		80001: '',
	},
	spid: 0,
    token: tokens.genesis,
    quoteToken: tokens.quick,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:8.72,
	platform: 'Quickswap',
	rewarder:'0x3620418dd43853c35ff8df90cab5508fb5df46bf',
	rewarderToken:'0x51869836681bce74a514625c856afb697a013797',
  },
  {
    pid: 118,
    lpSymbol: 'GENESIS - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x51869836681bce74a514625c856afb697a013797/0x831753dd7087cac61ab5644b308642cc1c33dc13',
	strategyAddress:{
		137: '0xefff8d2892d36b81b8258994d6f25f73270a2184',
		80001: '',
	},
    lpAddresses: {
      137: '0xf0696be85fa54f7a8c9f20aa98aa4409cd5c9d1b',
      80001: '',
    },
	masterChefAddress:{
		137: '0x3620418dd43853c35ff8df90cab5508fb5df46bf',
		80001: '',
	},
	spid: 0,
    token: tokens.genesis,
    quoteToken: tokens.quick,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:8.72,
	platform: 'Quickswap',
	rewarder:'0x3620418dd43853c35ff8df90cab5508fb5df46bf',
	rewarderToken:'0x51869836681bce74a514625c856afb697a013797',
  },
  {
    pid: 119,
    lpSymbol: 'MANA - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
	strategyAddress:{
		137: '0x2f6c7356c6bc29f32d32786b2994c6570ce8a256',
		80001: '',
	},
    lpAddresses: {
      137: '0x6b0ce31ead9b14c2281d80a5dde903ab0855313a',
      80001: '',
    },
	masterChefAddress:{
		137: '0xee61b0c32adf887d265236f57ac0a2449cc931c7',
		80001: '',
	},
	spid: 0,
    token: tokens.mana,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:130,
	platform: 'Quickswap',
  },
  {
    pid: 120,
    lpSymbol: 'MANA - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
	strategyAddress:{
		137: '0x8806772f6d061f156709ce6bb2cedd6354bd4456',
		80001: '',
	},
    lpAddresses: {
      137: '0x6b0ce31ead9b14c2281d80a5dde903ab0855313a',
      80001: '',
    },
	masterChefAddress:{
		137: '0xee61b0c32adf887d265236f57ac0a2449cc931c7',
		80001: '',
	},
	spid: 0,
    token: tokens.mana,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:130,
	platform: 'Quickswap',
  },
  {
    pid: 109,
    lpSymbol: 'SHIB - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
	strategyAddress:{
		137: '0x8928926E27bD02Ca6619eD313d2808e80a3194Ae',
		80001: '',
	},
    lpAddresses: {
      137: '0x5fb641de2663e8a94c9dea0a539817850d996e99',
      80001: '',
    },
	masterChefAddress:{
		137: '0x807a2ef804a8557bf5ec9c03ff869888e6af8e83',
		80001: '',
	},
	spid: 0,
    token: tokens.shib,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:38.74,
	platform: 'Quickswap',
  },
  {
    pid: 101,
    lpSymbol: 'MATIC - BANANA',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x5d47baba0d66083c52009271faf3f50dcc01023c',
	strategyAddress:{
		137: '0x602F5428998859613C4518Df7F511E7287a34cE6',
		80001: '',
	},
    lpAddresses: {
      137: '0x034293f21f1cce5908bc605ce5850df2b1059ac0',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 0,
    token: tokens.banana,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:5.82,
	isBurning:true,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 102,
    lpSymbol: 'USDC - DAI',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	strategyAddress:{
		137: '0xa6051583c919bcEF56b194c07a30E753867ed31C',
		80001: '',
	},
    lpAddresses: {
      137: '0x5b13B583D4317aB15186Ed660A1E4C65C10da659',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 5,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isStable: true,
	isBurning:true,
	allocPointName: 'allocPoint',
	lpRewardsApr:7.99,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 103,
    lpSymbol: 'KOGECOIN-MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x13748d548d95d78a3c83fe3f32604b4796cffa23',
	strategyAddress:{
		137: '0xa4C058f5B958E32a0af9fB4767Ceeb17CcAcADE6',
		80001: '',
	},
    lpAddresses: {
      137: '0x3885503aef5e929fcb7035fbdca87239651c8154',
      80001: '',
    },
	masterChefAddress:{
		137: '0x6275518a63e891b1bC54FEEBBb5333776E32fAbD',
		80001: '',
	},
	spid: 0,
    token: tokens.kogecoin,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.kogecoin,
	emissionFunctionName: 'rewardPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:9.58,
	platform: 'Kogefarm'
  },
{
    pid: 83,
    lpSymbol: 'PolyDoge - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x831753dd7087cac61ab5644b308642cc1c33dc13/0x8a953cfe442c5e8855cc6c61b1293fa648bae472',
	strategyAddress:{
		137: '0x713bce1fc7AC14b26580b74D851F265934f72EaF',
		80001: '',
	},
    lpAddresses: {
      137: '0xbedee6a7c572aa855a0c84d2f504311d482862f4',
      80001: '',
    },
	masterChefAddress:{
		137: '0x403A2604226585Cb1e07D644780930D650EA4b73',
		80001: '',
	},
	spid: 0,
    token: tokens.polydoge,
    quoteToken: tokens.quick,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:13.15,
	platform: 'Quickswap',
  },  
  {
    pid: 106,
    lpSymbol: 'MATIC - WETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x97d8e91ead53dee8eae1675bba56f0e1929ba8b4',
		80001: '',
	},
    lpAddresses: {
      137: '0xadbf1854e5883eb8aa7baf50705338739e558e5b',
      80001: '',
    },
	masterChefAddress:{
		137: '0x3c1f53fed2238176419F8f897aEc8791C499e3c8',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:8.34,
	platform: 'Quickswap',
	rewarder:'0x3c1f53fed2238176419F8f897aEc8791C499e3c8',
  },  
  {
    pid: 104,
    lpSymbol: 'PolyDoge - MATIC',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x8a953cfe442c5e8855cc6c61b1293fa648bae472',
	strategyAddress:{
		137: '0x22eff488ac0c669706ee1edd5114a7b23ad1bb1f',
		80001: '',
	},
    lpAddresses: {
      137: '0x5d9d66ac0db91ec463fb3e9e5b1513dbff02fd0f',
      80001: '',
    },
	masterChefAddress:{
		137: '0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
		80001: '',
	},
	spid: 8,
    token: tokens.polydoge,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.crystl,
	emissionFunctionName: 'crystalPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:138,
	platform: 'Crystal',
  }, 
  {
    pid: 51,
    lpSymbol: 'USDC - INSUR',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x8a0e8b4b0903929f47c3ea30973940d4a9702067',
	strategyAddress:{
		137: '0x51e043a2135b7231Ec8d594C3FFa59382983Ab2a',
		80001: '',
	},
    lpAddresses: {
      137: '0x6F21996181915D688C787A1eC7C987E2A31B6829',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 41,
    token: tokens.insur,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:8.64,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
  {
    pid: 64,
    lpSymbol: 'FOR - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x546b4c391520e6652897c65153074088bfc0a909',
	strategyAddress:{
		137: '0x585b45A2C74c7E653D61f100048e37953cD3A0d3',
		80001: '',
	},
    lpAddresses: {
      137: '0xA1c3eb6fE2bB452AaC4d9247478594bf04750017',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 17,
    token: tokens.for,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:9.19,
	platform: 'Dinoswap'
  },
  {
    pid: 59,
    lpSymbol: 'EROWAN - AKT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821/0xf14fbc6b30e2c4bc05a1d4fbe34bf9f14313309d',
	strategyAddress:{
		137: '0x0cD184d508A3A1901216fAD0BbC18B98f9cc9cc7',
		80001: '',
	},
    lpAddresses: {
      137: '0xA651EF83FA6a90e76206De4e79A5c69f80994556',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 24,
    token: tokens.akt,
    quoteToken: tokens.erowan,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:5.7,
	platform: 'Dinoswap'
  }, 
  {
    pid: 57,
    lpSymbol: 'EROWAN - IRIS',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x3dc6052a693e4a2fc28eb2ea12fe0cfd3bd221d1/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821',
	strategyAddress:{
		137: '0x40ad769BA5aef3a2e8c6F97a92564a6829Dea36D',
		80001: '',
	},
    lpAddresses: {
      137: '0x58fFB271c6F3D92f03C49e08E2887810F65b8Cd6',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 23,
    token: tokens.iris,
    quoteToken: tokens.erowan,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:3.42,
	platform: 'Dinoswap'
  }, 
  {
    pid: 3,
    lpSymbol: 'USDC - MAI',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	strategyAddress:{
		137: '0x60543048F48b924892CbAF686274513Bb3dca30E',
		80001: '',
	},
    lpAddresses: {
      137: '0x160532D2536175d65C03B97b0630A9802c274daD',
      80001: '',
    },
	masterChefAddress:{
		137: '0x574Fe4E8120C4Da1741b5Fd45584de7A5b521F0F',
		80001: '',
	},
	spid: 1,
    token: tokens.mai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.qi,
	emissionFunctionName: 'rewardPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.5,
	platform: 'Qi Dao',
  }, 
  {
    pid: 4,
    lpSymbol: 'MATIC - QI',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
	strategyAddress:{
		137: '0x7f3e907AB4a4458bB34F20fd1A8268933abCE0ac',
		80001: '',
	},
    lpAddresses: {
      137: '0x9A8b2601760814019B7E6eE0052E25f1C623D1E6',
      80001: '',
    },
	masterChefAddress:{
		137: '0x574Fe4E8120C4Da1741b5Fd45584de7A5b521F0F',
		80001: '',
	},
	spid: 4,
    token: tokens.qi,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.qi,
	emissionFunctionName: 'rewardPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.84,
	platform: 'Qi Dao',
  }, 
  {
    pid: 5,
    lpSymbol: 'MATIC - BANANA',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x5d47baba0d66083c52009271faf3f50dcc01023c',
	strategyAddress:{
		137: '0x490cDACea9a68E0305f613c50AFEA464c09af79C',
		80001: '',
	},
    lpAddresses: {
      137: '0x034293f21f1cce5908bc605ce5850df2b1059ac0',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 0,
    token: tokens.banana,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:5.82,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 6,
    lpSymbol: 'MATIC - WETH',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
	strategyAddress:{
		137: '0x46aAdC175eF9272Ab47Ce6c923249478d09CA91d',
		80001: '',
	},
    lpAddresses: {
      137: '0x6Cf8654e85AB489cA7e70189046D507ebA233613',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 1,
    token: tokens.weth,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:9.98,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 7,
    lpSymbol: 'MATIC - DAI',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
	strategyAddress:{
		137: '0x3a342D42f38C4b19624f7e86192924ec0E77fb25',
		80001: '',
	},
    lpAddresses: {
      137: '0xd32f3139A214034A0f9777c87eE0a064c1FF6AE2',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 2,
    token: tokens.dai,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:9.98,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 8,
    lpSymbol: 'MATIC - USDT',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
	strategyAddress:{
		137: '0x644ec9aBc0915C6819B1061723Dc82d271A5AECa',
		80001: '',
	},
    lpAddresses: {
      137: '0x65D43B64E3B31965Cd5EA367D4c2b94c03084797',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 3,
    token: tokens.usdt,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:20.47,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 9,
    lpSymbol: 'MATIC - WBTC',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
	strategyAddress:{
		137: '0x07c1DA21bB9EB2C19b89a7c9B51DB62CAdc5c16E',
		80001: '',
	},
    lpAddresses: {
      137: '0xe82635a105c520fd58e597181cBf754961d51E3e',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 4,
    token: tokens.wbtc,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:9.94,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 10,
    lpSymbol: 'USDC - DAI',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	strategyAddress:{
		137: '0x37afdE115595FA0C937235559b76782132A259CA',
		80001: '',
	},
    lpAddresses: {
      137: '0x5b13B583D4317aB15186Ed660A1E4C65C10da659',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 5,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isStable: true,
	isBurning:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:7.99,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 11,
    lpSymbol: 'MATIC - BNB',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
	strategyAddress:{
		137: '0xD66E630A457afD337A782f4E8B7a31FC0915d3da',
		80001: '',
	},
    lpAddresses: {
      137: '0x0359001070cF696D5993E0697335157a6f7dB289',
      80001: '',
    },
	masterChefAddress:{
		137: '0x54aff400858Dcac39797a81894D9920f16972D1D',
		80001: '',
	},
	spid: 6,
    token: tokens.bnb,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.banana,
	emissionFunctionName: 'bananaPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	allocPointName: 'allocPoint',
	lpRewardsApr:2.7,
	isBurning:false,
	platform: 'Apeswap',
	rewarder: '0x1F234B1b83e21Cb5e2b99b4E498fe70Ef2d6e3bf'
  },
  {
    pid: 12,
    lpSymbol: 'USDC - USDT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/0xc2132D05D31c914a87C6611C10748AEb04B58e8F/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	strategyAddress:{
		137: '0xD41cE6a0EAd76d5420630cE6b1b2f8bCA2ba18C8',
		80001: '',
	},
    lpAddresses: {
      137: '0x2cf7252e74036d1da831d11089d326296e64a728',
      80001: '',
    },
	masterChefAddress:{
		137: '0xAFB76771C98351Aa7fCA13B130c9972181612b54',
		80001: '',
	},
	spid: 0,
    token: tokens.usdc,
    quoteToken: tokens.usdt,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:5.93,
	platform: 'Quickswap',
  },
  {
    pid: 13,
    lpSymbol: 'QUICK - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/0x831753DD7087CaC61aB5644b308642cc1c33Dc13/0x9Bbcda2606e616659b118399A2823E8a392f55DA',
	strategyAddress:{
		137: '0x9FF08d9B14A386D957995Cee8E1111eD8087fa86',
		80001: '',
	},
    lpAddresses: {
      137: '0x1f1e4c845183ef6d50e9609f16f6f9cae43bc9cb',
      80001: '',
    },
	masterChefAddress:{
		137: '0x939290ed45514e82900ba767bbcfa38ee1067039',
		80001: '',
	},
	spid: 0,
    token: tokens.quick,
    quoteToken: tokens.usdc,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:26,
	platform: 'Quickswap',
  },
  {
    pid: 14,
    lpSymbol: 'ETH - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x071545a88aA89bc7Ca9A536321D7b47d84773029',
		80001: '',
	},
    lpAddresses: {
      137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d',
      80001: '',
    },
	masterChefAddress:{
		137: '0xbB703E95348424FF9e94fbE4FB524f6d280331B8',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.usdc,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:13.13,
	platform: 'Quickswap',
  },
  {
    pid: 17,
    lpSymbol: 'ETH - USDT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0xBDc2de50Feb124B5d6b671162D0206fe199Ba382',
		80001: '',
	},
    lpAddresses: {
      137: '0xf6422b997c7f54d1c6a6e103bcb1499eea0a7046',
      80001: '',
    },
	masterChefAddress:{
		137: '0x45a5CB25F3E3bFEe615F6da0731740093F59b768',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.usdt,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:16.06,
	platform: 'Quickswap',
  },
  {
    pid: 19,
    lpSymbol: 'wBTC - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x63a5603b05c733886B63067A06479325e1aCe5F5',
		80001: '',
	},
    lpAddresses: {
      137: '0xdc9232e2df177d7a12fdff6ecbab114e2231198d',
      80001: '',
    },
	masterChefAddress:{
		137: '0x2972175e1a35C403B5596354D6459C34Ae6A1070',
		80001: '',
	},
	spid: 0,
    token: tokens.wbtc,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:2.65,
	platform: 'Quickswap',
  },
  {
    pid: 20,
    lpSymbol: 'ETH - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x831753dd7087cac61ab5644b308642cc1c33dc13',
	strategyAddress:{
		137: '0x86D2eaAF608535E64a4E580B6a75bAFa21d2FD9E',
		80001: '',
	},
    lpAddresses: {
      137: '0x1bd06b96dd42ada85fdd0795f3b4a79db914add5',
      80001: '',
    },
	masterChefAddress:{
		137: '0x5BcFcc24Db0A16b1C01BAC1342662eBd104e816c',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.quick,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:12.83,
	platform: 'Quickswap',
  },
  {
    pid: 21,
    lpSymbol: 'MAI - USDT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0xa3fa99a148fa48d14ed51d610c367c61876997f1/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0x36f8764cB439828B2D15b72817C8de691bdd6dA7',
		80001: '',
	},
    lpAddresses: {
      137: '0xe89fae1b4ada2c869f05a0c96c87022dadc7709a',
      80001: '',
    },
	masterChefAddress:{
		137: '0x06e49078b1900A8489462Cd2355ED8c09f507499',
		80001: '',
	},
	spid: 0,
    token: tokens.mai,
    quoteToken: tokens.usdt,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:0.39,
	platform: 'Quickswap',
  }, 
  {
    pid: 23,
    lpSymbol: 'MATIC - MAI',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0xa3fa99a148fa48d14ed51d610c367c61876997f1',
	strategyAddress:{
		137: '0x60E681A83dB7211c055a7a870D7f2c4054793f52',
		80001: '',
	},
    lpAddresses: {
      137: '0x7805b64e2d99412d3b8f10dfe8fc55217c5cc954',
      80001: '',
    },
	masterChefAddress:{
		137: '0x5F709F81cdA3E84fC2af3662B8B8C3F4f44e3d4E',
		80001: '',
	},
	spid: 0,
    token: tokens.mai,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:11.07,
	platform: 'Quickswap',
  },
  {
    pid: 24,
    lpSymbol: 'AVAX - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b',
	strategyAddress:{
		137: '0x15d4Ea58e472bBFb291D731D109B4Dc40c7eC034',
		80001: '',
	},
    lpAddresses: {
      137: '0xeb477ae74774b697b5d515ef8ca09e24fee413b5',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0cAB010bA055a9F3B3f987BA39eE0ad3E2d1a830',
		80001: '',
	},
	spid: 0,
    token: tokens.avax,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:19.90,
	platform: 'Quickswap',
  },
  {
    pid: 25,
    lpSymbol: 'UNI - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xb33eaad8d922b1083446dc23f610c2567fb5180f',
	strategyAddress:{
		137: '0x9Fae7DFF186C27b123A7739e0A72d82e08c325B6',
		80001: '',
	},
    lpAddresses: {
      137: '0xf7135272a5584eb116f5a77425118a8b4a2ddfdb',
      80001: '',
    },
	masterChefAddress:{
		137: '0x76cC4059Dd19518c377934CD799615B3543967fd',
		80001: '',
	},
	spid: 0,
    token: tokens.uni,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:3.29,
	platform: 'Quickswap',
  },
  {
    pid: 26,
    lpSymbol: 'SOL - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x7dff46370e9ea5f0bad3c4e29711ad50062ea7a4',
	strategyAddress:{
		137: '0x34e2f2a43d1bCDeBa6b63f505E2433340d072936',
		80001: '',
	},
    lpAddresses: {
      137: '0x898386dd8756779a4ba4f1462891b92dd76b78ef',
      80001: '',
    },
	masterChefAddress:{
		137: '0xB332b9D67E20bb8Ce4B93308A63C2EE2F846D372',
		80001: '',
	},
	spid: 0,
    token: tokens.sol,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:11.2,
	platform: 'Quickswap',
  }, 
  {
    pid: 27,
    lpSymbol: 'AAVE - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xd6df932a45c0f255f85145f286ea0b292b21c90b',
	strategyAddress:{
		137: '0xD4CFa1Da948714E3cf3Ba06Bd18f0ddde8cec436',
		80001: '',
	},
    lpAddresses: {
      137: '0x90bc3e68ba8393a3bf2d79309365089975341a43',
      80001: '',
    },
	masterChefAddress:{
		137: '0x9891548FB271C2350bd0FA25eb56A3b558cD4A64',
		80001: '',
	},
	spid: 0,
    token: tokens.aave,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:2.03,
	platform: 'Quickswap',
  },
  {
    pid: 28,
    lpSymbol: 'QI - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x580a84c73811e1839f75d86d75d88cca0c241ff4/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x19CDA79b8701a5Edd637F3E5519C0FC54c39802E',
		80001: '',
	},
    lpAddresses: {
      137: '0x8c1b40ea78081b70f661c3286c74e71b4602c9c0',
      80001: '',
    },
	masterChefAddress:{
		137: '0x17fE4630A855FF6e546C19c315BE7f3ED01f38Ff',
		80001: '',
	},
	spid: 0,
    token: tokens.qi,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:3.88,
	platform: 'Quickswap',
  },
  {
    pid: 29,
    lpSymbol: 'LINK - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x8f8B31B034e5E4f7C333c10DD5eB21b4fab84ba0',
		80001: '',
	},
    lpAddresses: {
      137: '0x5ca6ca6c3709e1e6cfe74a50cf6b2b6ba2dadd67',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1b077a0586b2ABD4062a39E6368E256dB2F723c4',
		80001: '',
	},
	spid: 0,
    token: tokens.link,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:4.12,
	platform: 'Quickswap',
  },
  {
    pid: 30,
    lpSymbol: 'FTM - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0xb85517b87bf64942adf3a0b9e4c71e4bc5caa4e5',
	strategyAddress:{
		137: '0x524F49fE7c919587c4950eF7454E5aD1eb1bD608',
		80001: '',
	},
    lpAddresses: {
      137: '0xd2b61a42d3790533fedc2829951a65120624034a',
      80001: '',
    },
	masterChefAddress:{
		137: '0xf81e664c8277d461df561b353d50c4b698144664',
		80001: '',
	},
	spid: 0,
    token: tokens.ftm,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:18.12,
	platform: 'Quickswap',
  },
  {
    pid: 124,
    lpSymbol: 'FTM - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0xb85517b87bf64942adf3a0b9e4c71e4bc5caa4e5',
	strategyAddress:{
		137: '0x33fde2e193c2408bcfefbd54035535c099da0848',
		80001: '',
	},
    lpAddresses: {
      137: '0xd2b61a42d3790533fedc2829951a65120624034a',
      80001: '',
    },
	masterChefAddress:{
		137: '0xf81e664c8277d461df561b353d50c4b698144664',
		80001: '',
	},
	spid: 0,
    token: tokens.ftm,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:18.12,
	platform: 'Quickswap',
  },
  {
    pid: 125,
    lpSymbol: 'ONE - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0x80c0cbdb8d0b190238795d376f0bd57fd40525f2',
	strategyAddress:{
		137: '0xd10a8414ff378fe335212781beea643ae5c19007',
		80001: '',
	},
    lpAddresses: {
      137: '0x28c5367d8a4e85f8d7b41a0ca2579e66a58fccb6',
      80001: '',
    },
	masterChefAddress:{
		137: '0x28b833473e047f6116c46d8ed5117708eeb151f9',
		80001: '',
	},
	spid: 0,
    token: tokens.one,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:true,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:22.73,
	platform: 'Quickswap',
  },
  {
    pid: 122,
    lpSymbol: 'ONE - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0x80c0cbdb8d0b190238795d376f0bd57fd40525f2',
	strategyAddress:{
		137: '0xdd5d0c1c65caa08eb72218eac6163be0f954960b',
		80001: '',
	},
    lpAddresses: {
      137: '0x28c5367d8a4e85f8d7b41a0ca2579e66a58fccb6',
      80001: '',
    },
	masterChefAddress:{
		137: '0x28b833473e047f6116c46d8ed5117708eeb151f9',
		80001: '',
	},
	spid: 0,
    token: tokens.one,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:22.73,
	platform: 'Quickswap',
  },
  {
    pid: 123,
    lpSymbol: 'CRO - MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/add/ETH/0xf2d8124b8f9267dad61351c7ad252362880c6638',
	strategyAddress:{
		137: '0x8aa3b8d0a1f7fe4c6a138872c8ff76cbaaa9db1e',
		80001: '',
	},
    lpAddresses: {
      137: '0xfd168748dd07a32a401e800240aec8ec6efc706f',
      80001: '',
    },
	masterChefAddress:{
		137: '0xd2a750c2ce25e47c3a0abe9b5966a20e60288091',
		80001: '',
	},
	spid: 0,
    token: tokens.cro,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:11.46,
	platform: 'Quickswap',
  },
  {
    pid: 31,
    lpSymbol: 'DAI - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0x823A997b48E9fC5b348524E20821d35a54FD711A',
		80001: '',
	},
    lpAddresses: {
      137: '0x4a35582a710e1f4b2030a3f826da20bfb6703c09',
      80001: '',
    },
	masterChefAddress:{
		137: '0x8d6b2dBa9e85b897Dc97eD262C1aa3e4D76477dF',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.dai,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:12.39,
	platform: 'Quickswap',
  },
  {
    pid: 32,
    lpSymbol: 'MAI - DAI',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063/0xa3fa99a148fa48d14ed51d610c367c61876997f1',
	strategyAddress:{
		137: '0xf2b5D3B6EcF3D9306fefd4381d3459EF21B07e0E',
		80001: '',
	},
    lpAddresses: {
      137: '0x74214f5d8aa71b8dc921d8a963a1ba3605050781',
      80001: '',
    },
	masterChefAddress:{
		137: '0xb827B23e2276ceB912CB42088ab064800447c158',
		80001: '',
	},
	spid: 0,
    token: tokens.dai,
    quoteToken: tokens.mai,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:0.86,
	platform: 'Quickswap',
  },
  {
    pid: 33,
    lpSymbol: 'MATIC - USDC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/ETH/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	strategyAddress:{
		137: '0x2AEb3169dbE852Ac087DCcFf51F7366ffaf0F2e6',
		80001: '',
	},
    lpAddresses: {
      137: '0xBB0dbC0e2B6fBeF902BC046ECFC2D664658BaafC',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 2,
    token: tokens.usdc,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },
  {
    pid: 34,
    lpSymbol: 'pBREW - MATIC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/ETH/0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
	strategyAddress:{
		137: '0x313B1e682BB10587df5C265a6DbDCbe2F79F5Bf3',
		80001: '',
	},
    lpAddresses: {
      137: '0x4f03233c742Ea20e73b46d5E33242b7770274ab8',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 3,
    token: tokens.pbrew,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },
  {
    pid: 35,
    lpSymbol: 'pBREW - USDC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
	strategyAddress:{
		137: '0xC5081C12218A2BE437A0B7f589BC70BAc7B67f83',
		80001: '',
	},
    lpAddresses: {
      137: '0x723e866989Cacc8EFC346e1DCf5a9D73572bCFc8',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 1,
    token: tokens.pbrew,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  }, 
  {
    pid: 36,
    lpSymbol: 'USDT - USDC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0xe455BE4F595290AC019d28cB5E83863AF030106c',
		80001: '',
	},
    lpAddresses: {
      137: '0x7f4cdeA583b6c4dd3241fD6618236FF1fE57f549',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 17,
    token: tokens.usdt,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  }, 
  {
    pid: 37,
    lpSymbol: 'BREW - USDC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xa0b20decbc557e3f68e140ed5a0c69bc865f865a',
	strategyAddress:{
		137: '0x5863e7f9a6448B52CfCa5721A096aD7b951CF926',
		80001: '',
	},
    lpAddresses: {
      137: '0x248C328b1048f9B6e7e00D61a02E9cA3F8c2bf50',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 7,
    token: tokens.brew,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  }, 
  {
    pid: 38,
    lpSymbol: 'USDC - DAI',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0x8ebfE4E7DaFd1399B9bb6B4138df01D1aE758E88',
		80001: '',
	},
    lpAddresses: {
      137: '0x815c2D1894Daf25935fa909bE35Ee1Fed67b2B97',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 5,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },
  {
    pid: 39,
    lpSymbol: 'UST - USDC',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x692597b009d13c4049a947cab2239b7d6517875f',
	strategyAddress:{
		137: '0x9908fa8EAFE595dF17cBCf54fdcce5732558E602',
		80001: '',
	},
    lpAddresses: {
      137: '0xFCcabD913DF5a17721c30bF23fEB7eA865422f45',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 14,
    token: tokens.ust,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },  
{
    pid: 40,
    lpSymbol: 'pBREW',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/swap?outputCurrency=0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
	strategyAddress:{
		137: '0x8a7851f8a401543542788363d346340Fb4b9b68d',
		80001: '',
	},
    lpAddresses: {
      137: '0xb5106a3277718ecad2f20ab6b86ce0fee7a21f09',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 0,
    token: tokens.pbrew,
    quoteToken: tokens.pbrew,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },    
{
    pid: 41,
    lpSymbol: 'BREW',  
	exchange: 'Cafe LP',
	baseLiquidityUrl: 'https://polygondex.cafeswap.finance/#/swap?outputCurrency=0xa0b20decbc557e3f68e140ed5a0c69bc865f865a',
	strategyAddress:{
		137: '0xBF9a388e5e416d658194Cce0Eb44552206138AEc',
		80001: '',
	},
    lpAddresses: {
      137: '0xa0b20DecBc557E3f68E140eD5a0c69bc865F865A',
      80001: '',
    },
	masterChefAddress:{
		137: '0xca2DeAc853225f5a4dfC809Ae0B7c6e39104fCe5',
		80001: '',
	},
	spid: 9,
    token: tokens.brew,
    quoteToken: tokens.brew,
	rewardToken: tokens.pbrew,
	emissionFunctionName: 'brewPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	isBurning:false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Cafeswap',
  },       
  {
    pid: 42,
    lpSymbol: 'USDC - USDT',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0x764F7E394AD704D62DeE21350c5024a5b34711A1',
		80001: '',
	},
    lpAddresses: {
      137: '0x4B1F1e2435A9C96f7330FAea190Ef6A7C8D70001',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 8,
    token: tokens.usdt,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'allocPoint',
	lpRewardsApr:7.3,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 43,
    lpSymbol: 'USDC - DAI',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0xbf2dEEF7a090650cae3542F77396ECbD39471f06',
		80001: '',
	},
    lpAddresses: {
      137: '0xCD578F016888B57F1b1e3f887f392F0159E26747',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 11,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'allocPoint',
	lpRewardsApr:9.87,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  },
{
    pid: 44,
    lpSymbol: 'AVAX - WETH',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xFa0B98d47e31054349c6Ae6922FaE158aEf695f4',
		80001: '',
	},
    lpAddresses: {
      137: '0x1274De0DE2e9D9b1d0E06313c0E5EdD01CC335eF',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 38,
    token: tokens.avax,
    quoteToken: tokens.weth,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:38.39,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 45,
    lpSymbol: 'WETH - DAI',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0x904608564CFAC14a353A595F6aA38A5D99937E36',
		80001: '',
	},
    lpAddresses: {
      137: '0x6FF62bfb8c12109E8000935A6De54daD83a4f39f',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 5,
    token: tokens.dai,
    quoteToken: tokens.weth,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:8.33,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  },  
{
    pid: 46,
    lpSymbol: 'WETH - USDT',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0x81FDd8A300625B48667cCb4936d5Cc80e2b06C1C',
		80001: '',
	},
    lpAddresses: {
      137: '0xc2755915a85C6f6c1C0F3a86ac8C058F11Caa9C9',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 2,
    token: tokens.usdt,
    quoteToken: tokens.weth,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:8.7,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 47,
    lpSymbol: 'USDC - BIFI',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xfbdd194376de19a88118e84e279b977f165d01b8',
	strategyAddress:{
		137: '0x3C9157d72CB005EBA78b1D960657ce607c3B1D65',
		80001: '',
	},
    lpAddresses: {
      137: '0x180237bd326d5245D0898336F54b3c8012c5c62f',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 23,
    token: tokens.bifi,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:11.24,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  },
{
    pid: 48,
    lpSymbol: 'MATIC - WETH',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/ETH/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xdE7193f78d24dcC569ECcB1b27348c48Aa93505C',
		80001: '',
	},
    lpAddresses: {
      137: '0xc4e595acDD7d12feC385E5dA5D43160e8A0bAC0E',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 0,
    token: tokens.wmatic,
    quoteToken: tokens.weth,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:19.44,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  },
{
    pid: 49,
    lpSymbol: 'USDC - WETH',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x4f494049133f3C12d36c4caa31C0b675c567E513',
		80001: '',
	},
    lpAddresses: {
      137: '0x34965ba0ac2451A34a0471F04CCa3F990b8dea27',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 1,
    token: tokens.weth,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:29.19,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 50,
    lpSymbol: 'USDC - TUSD',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x2e1ad108ff1d8c782fcbbb89aad783ac49586756',
	strategyAddress:{
		137: '0xdd1f9b4358116d736a4DB150a39789B070937339',
		80001: '',
	},
    lpAddresses: {
      137: '0x4AB66a7138F50Db9B7aDaF8CE8aFB3F638B22f7f',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 42,
    token: tokens.tusd,
    quoteToken: tokens.usdc,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'allocPoint',
	lpRewardsApr:0.06,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 52,
    lpSymbol: 'WETH - MANA',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
	strategyAddress:{
		137: '0x3ecD812200D4e1583d97Bbc5e871707BD9dD0b25',
		80001: '',
	},
    lpAddresses: {
      137: '0xc48AE82ca34C63887b975F20ABA91a38f2a900B8',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
		80001: '',
	},
	spid: 34,
    token: tokens.mana,
    quoteToken: tokens.weth,
	rewardToken: tokens.sushi,
	emissionFunctionName: 'sushiPerSecond',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'allocPoint',
	lpRewardsApr:62.51,
	platform: 'Sushiswap',
	rewarder: '0xa3378Ca78633B3b9b2255EAa26748770211163AE'
  }, 
{
    pid: 53,
    lpSymbol: 'DINO - USDC',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xaa9654becca45b5bdfa5ac646c939c62b527d394',
	strategyAddress:{
		137: '0x91881440A99b71E77ED59F7172932d537e0B4F3f',
		80001: '',
	},
    lpAddresses: {
      137: '0x3324af8417844e70b81555A6D1568d78f4D4Bf1f',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 10,
    token: tokens.dino,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:4.37,
	platform: 'Dinoswap'
  },  
  {
    pid: 54,
    lpSymbol: 'DINO - WETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xaa9654becca45b5bdfa5ac646c939c62b527d394',
	strategyAddress:{
		137: '0xb15F38B4fa59F4470494f312BC99D488fC993Db8',
		80001: '',
	},
    lpAddresses: {
      137: '0x9f03309A588e33A239Bf49ed8D68b2D45C7A1F11',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 11,
    token: tokens.dino,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:5.72,
	platform: 'Dinoswap'
  }, 
{
    pid: 55,
    lpSymbol: 'EROWAN - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x831753dd7087cac61ab5644b308642cc1c33dc13/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821',
	strategyAddress:{
		137: '0x925a2e1C8B3118359d27Cc2c73a8beC49f7478B4',
		80001: '',
	},
    lpAddresses: {
      137: '0x631F39D22430E889A3CFbEA4FD73Ed101059075f',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 21,
    token: tokens.quick,
    quoteToken: tokens.erowan,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:46.24,
	platform: 'Dinoswap'
  },
{
    pid: 56,
    lpSymbol: 'DFYN - WETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://exchange.dfyn.network/#/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97',
	strategyAddress:{
		137: '0xfdcf445b6e37Ca9a7C8a57153937baE0777EA4cf',
		80001: '',
	},
    lpAddresses: {
      137: '0x6fA867BBFDd025780a8CFE988475220AfF51FB8b',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 1,
    token: tokens.dfyn,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:28.73,
	platform: 'Dinoswap'
  }, 
{
    pid: 58,
    lpSymbol: 'EROWAN - XPRT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821/0xb3b9c016ad1e9f7efdae451b04ef696e05658b32',
	strategyAddress:{
		137: '0x8a10d9391687100f32084e9DaD41be13d9B52207',
		80001: '',
	},
    lpAddresses: {
      137: '0xF366DF119532b2e0F4E416C81d6FF7728a60FE7d',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 25,
    token: tokens.xprt,
    quoteToken: tokens.erowan,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:6.23,
	platform: 'Dinoswap'
  },
{
    pid: 60,
    lpSymbol: 'EROWAN - REGEN',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821/0xec482de9569a5ea3dd9779039b79e53f15791fde',
	strategyAddress:{
		137: '0x1456FD6E9C1f6ca4d46e27fC50afd69755fAe0F1',
		80001: '',
	},
    lpAddresses: {
      137: '0x66c37a00E426A613B188180198AAC12B0b4aE4D4',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 26,
    token: tokens.regen,
    quoteToken: tokens.erowan,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:10.26,
	platform: 'Dinoswap'
  }, 
{
    pid: 61,
    lpSymbol: 'UST - USDT',  
	exchange: 'Dfyn LP',
	baseLiquidityUrl: 'https://exchange.dfyn.network/#/add/0x692597b009d13c4049a947cab2239b7d6517875f/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0x004C4808E3c3b07F66834dE28569F19Fb31DA29b',
		80001: '',
	},
    lpAddresses: {
      137: '0x39BEd7f1C412ab64443196A6fEcb2ac20C707224',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 12,
    token: tokens.ust,
    quoteToken: tokens.usdt,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0.86,
	platform: 'Dinoswap'
  },
{
    pid: 62,
    lpSymbol: 'EROWAN - ATOM',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0xa7051c5a22d963b81d71c2ba64d46a877fbc1821/0xac51c4c48dc3116487ed4bc16542e27b5694da1b',
	strategyAddress:{
		137: '0x37cC57188A758401EaEfb54249A73b8B51161Ec5',
		80001: '',
	},
    lpAddresses: {
      137: '0x7051810A53030171F01d89e9AeBd8A599DE1B530',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 22,
    token: tokens.erowan,
    quoteToken: tokens.atom,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:10.08,
	platform: 'Dinoswap'
  }, 
{
    pid: 63,
    lpSymbol: 'WORK - USDC',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x6002410dda2fb88b4d0dc3c1d562f7761191ea80',
	strategyAddress:{
		137: '0xE41B466dBE91fF2435eDeD4296EA9B395a80880e',
		80001: '',
	},
    lpAddresses: {
      137: '0xAb0454B98dAf4A02EA29292E6A8882FB2C787DD4',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 20,
    token: tokens.work,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0.15,
	platform: 'Dinoswap'
  },  
{
    pid: 65,
    lpSymbol: 'DG - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x2a93172c8dccbfbc60a39d56183b7279a2f647b4',
	strategyAddress:{
		137: '0x469D391BD9788965BfaD0e44373b5b3Df86227eb',
		80001: '',
	},
    lpAddresses: {
      137: '0x27CE41B9eeB94cC122eF3B5e409b2900d3e0A629',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 16,
    token: tokens.dg,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:13.89,
	platform: 'Dinoswap'
  }, 
{
    pid: 66,
    lpSymbol: 'ORBS - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x614389eaae0a6821dc49062d56bda3d9d45fa2ff',
	strategyAddress:{
		137: '0xd61eB2D31275970c4BD24042d327E4715D5Fd6bE',
		80001: '',
	},
    lpAddresses: {
      137: '0xB2b6D423e535b57aaD06E9866803B95fB66152EA',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 19,
    token: tokens.orbs,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0.1,
	platform: 'Dinoswap'
  },
{
    pid: 67,
    lpSymbol: 'DNXC-USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xcaf5191fc480f43e4df80106c7695eca56e48b18',
	strategyAddress:{
		137: '0x3C66b50c8bC81f230a3D74421Be7D4a1541D9e70',
		80001: '',
	},
    lpAddresses: {
      137: '0xE169a660d720917B4fB7e95f045B6f60a64EB10A',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 18,
    token: tokens.dnxc,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:6.49,
	platform: 'Dinoswap'
  },
{
    pid: 68,
    lpSymbol: 'ROUTE - WETH',  
	exchange: 'Dfyn LP',
	baseLiquidityUrl: 'https://exchange.dfyn.network/#/add/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xCb71e46c7299379BA0cD20a704Caee221A3B5847',
		80001: '',
	},
    lpAddresses: {
      137: '0xeBC4f9B1cE66258AC3A48578FFEEba1330dDB68B',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 15,
    token: tokens.route,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:9.75,
	platform: 'Dinoswap'
  }, 
{
    pid: 69,
    lpSymbol: 'SFI - WETH',  
	exchange: 'Sushi LP',
	lpInfo: 'https://analytics.sushi.com/pairs/0x23a9292830fc80db7f563edb28d2fe6fb47f8624',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x35b937583F04A24963eb685F728a542240f28Dd8',
	strategyAddress:{
		137: '0x696ECba7C5226d37e6603043ad05e5b6609e15D9',
		80001: '',
	},
    lpAddresses: {
      137: '0x7C07CecD8cdd65C0daD449808cc5f9AD74C22bd1',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 14,
    token: tokens.sfi,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:6.11,
	platform: 'Dinoswap'
  },   
  {
    pid: 70,
    lpSymbol: 'ARPA - WETH',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xee800b277a96b0f490a1a732e1d6395fad960a26',
	strategyAddress:{
		137: '0x9c544d3770C6f1e14729e587934071c42BFDD627',
		80001: '',
	},
    lpAddresses: {
      137: '0x590F5E967d73eA06DAE9aED2788108DCF52dA269',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 13,
    token: tokens.arpa,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:8.43,
	platform: 'Dinoswap'
  },   
  {
    pid: 71,
    lpSymbol: 'BEL - WETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x28c388fb1f4fa9f9eb445f0579666849ee5eeb42/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xccB6c204E57228c5719CA290587004304509249E',
		80001: '',
	},
    lpAddresses: {
      137: '0x49ceCfa5c62b3A97F58CAd6B4aCc7c74810E1DDa',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 0,
    token: tokens.bel,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:8.74,
	platform: 'Dinoswap'
  },   
  {
    pid: 72,
    lpSymbol: 'XMARK - USDC',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xf153eff70dc0bf3b085134928daeea248d9b30d0',
	strategyAddress:{
		137: '0x80227866A9b8855652A4C08599a2089334fdc575',
		80001: '',
	},
    lpAddresses: {
      137: '0x97A95deb56d689802F02f50c25EBCda5d0A49591',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 2,
    token: tokens.xmark,
    quoteToken: tokens.usdc,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.75,
	platform: 'Dinoswap'
  },
{
    pid: 73,
    lpSymbol: 'SX - WETH',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
	strategyAddress:{
		137: '0x4ACe9DDFcF0027fe405b2E6Cecb7CD3157c4eEbE',
		80001: '',
	},
    lpAddresses: {
      137: '0x1bF9805B40a5f69c7d0f9E5d1Ab718642203c652',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 4,
    token: tokens.sx,
    quoteToken: tokens.weth,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0.61,
	platform: 'Dinoswap'
  },
  {
    pid: 74,
    lpSymbol: 'CGG - MATIC',  
	exchange: 'Sushi LP',
	baseLiquidityUrl: 'https://app.sushi.com/add/ETH/0x2ab4f9ac80f33071211729e45cfc346c1f8446d5',
	strategyAddress:{
		137: '0x80Be30DF9674Fef09B0E1e0cc2eBc303788d12f5',
		80001: '',
	},
    lpAddresses: {
      137: '0xd74d23d2f23CD06a7D94f740A74c6E906F0C9005',
      80001: '',
    },
	masterChefAddress:{
		137: '0x1948abC5400Aa1d72223882958Da3bec643fb4E5',
		80001: '',
	},
	spid: 5,
    token: tokens.cgg,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.dino,
	emissionFunctionName: 'dinoPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:22.46,
	platform: 'Dinoswap'
  },
  {
    pid: 75,
    lpSymbol: 'KOGECOIN-MATIC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x13748d548d95d78a3c83fe3f32604b4796cffa23',
	strategyAddress:{
		137: '0x8a9dd3D188B3FD3f216Be91dE17F7107B8f0565C',
		80001: '',
	},
    lpAddresses: {
      137: '0x3885503aef5e929fcb7035fbdca87239651c8154',
      80001: '',
    },
	masterChefAddress:{
		137: '0x6275518a63e891b1bC54FEEBBb5333776E32fAbD',
		80001: '',
	},
	spid: 0,
    token: tokens.kogecoin,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.kogecoin,
	emissionFunctionName: 'rewardPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:9.58,
	platform: 'Kogefarm'
  }, 
  {
    pid: 76,
    lpSymbol: 'pWINGS - MATIC',  
	exchange: 'pWINGS LP',
	baseLiquidityUrl: 'https://polygon-exchange.jetswap.finance/#/add/MATIC/0x845e76a8691423fbc4ecb8dd77556cb61c09ee25',
	strategyAddress:{
		137: '0x1D2C2cef8fA993E41A9C5Ac2F935A408158e8667',
		80001: '',
	},
    lpAddresses: {
      137: '0xa0a6e9a5185d5737cf6f7920cb417ea2f07f03b3',
      80001: '',
    },
	masterChefAddress:{
		137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
		80001: '',
	},
	spid: 2,
    token: tokens.pwings,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.pwings,
	emissionFunctionName: 'cakePerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.82,
	platform: 'Jetswap',
  },
{
    pid: 77,
    lpSymbol: 'pWINGS',  
	exchange: 'pWINGS LP',
	baseLiquidityUrl: 'https://polygon-exchange.jetswap.finance/#/swap?outputCurrency=0x845e76a8691423fbc4ecb8dd77556cb61c09ee25',
	strategyAddress:{
		137: '0xF5FA749CDC346c11288336C513de012E788F0961',
		80001: '',
	},
    lpAddresses: {
      137: '0x845e76a8691423fbc4ecb8dd77556cb61c09ee25',
      80001: '',
    },
	masterChefAddress:{
		137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
		80001: '',
	},
	spid: 0,
    token: tokens.pwings,
    quoteToken: tokens.pwings,
	rewardToken: tokens.pwings,
	emissionFunctionName: 'cakePerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:0,
	platform: 'Jetswap',
  },
{
    pid: 78,
    lpSymbol: 'TIME - USDC',  
	exchange: 'pWINGS LP',
	baseLiquidityUrl: 'https://polygon-exchange.jetswap.finance/#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x5c59d7cb794471a9633391c4927ade06b8787a90',
	strategyAddress:{
		137: '0x4d85FCD26aE1DF3A79F31694A7416cB7F9d9587A',
		80001: '',
	},
    lpAddresses: {
      137: '0xe44cfd418d8b8207eb0a059207c807922d165c05',
      80001: '',
    },
	masterChefAddress:{
		137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
		80001: '',
	},
	spid: 22,
    token: tokens.time,
    quoteToken: tokens.usdc,
	rewardToken: tokens.pwings,
	emissionFunctionName: 'cakePerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.08,
	platform: 'Jetswap',
  },
{
    pid: 79,
    lpSymbol: 'pNAUT - MATIC',  
	exchange: 'pWINGS LP',
	baseLiquidityUrl: 'https://polygon-exchange.jetswap.finance/#/add/MATIC/0xca469963a030a3670ed76832a6a181d280af108d',
	strategyAddress:{
		137: '0x8e9E197a3254e59444870cb4ce7F7cc04ED9584E',
		80001: '',
	},
    lpAddresses: {
      137: '0x9586c9add103547275ad86024f1dc6fe05040b35',
      80001: '',
    },
	masterChefAddress:{
		137: '0x4e22399070aD5aD7f7BEb7d3A7b543e8EcBf1d85',
		80001: '',
	},
	spid: 24,
    token: tokens.pnaut,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.pwings,
	emissionFunctionName: 'cakePerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:2.47,
	platform: 'Jetswap',
  }  ,  
  {
    pid: 84,
    lpSymbol: 'DAI - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0xB7414b0A75f87c4547fd29bfF76DD4d1263B242d',
		80001: '',
	},
    lpAddresses: {
      137: '0xf04adbf75cdfc5ed26eea4bbbb991db002036bdd',
      80001: '',
    },
	masterChefAddress:{
		137: '0xACb9EB5B52F495F09bA98aC96D8e61257F3daE14',
		80001: '',
	},
	spid: 0,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:4,
	platform: 'Quickswap',
  }, 
  {
    pid: 85,
    lpSymbol: 'GFI',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x874e178a2f3f3f9d34db862453cd756e7eab0381',
	strategyAddress:{
		137: '0xc7372959046177687f9d8d229C943a4e19488fa4',
		80001: '',
	},
    lpAddresses: {
      137: '0x874e178a2f3f3f9d34db862453cd756e7eab0381',
      80001: '',
    },
	masterChefAddress:{
		137: '0x8105662cf0f7c1a9d4066e7f2c81049049bc184d',
		80001: '',
	},
	spid: 0,
    token: tokens.gfi,
    quoteToken: tokens.gfi,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0,
	platform: 'Gravity',
  }, 
  {
    pid: 86,
    lpSymbol: 'GFI - USDC',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x874e178a2f3f3f9d34db862453cd756e7eab0381',
	strategyAddress:{
		137: '0x4d345ed11Ce81f1072e1029643BFb51dD662a09d',
		80001: '',
	},
    lpAddresses: {
      137: '0x96d6F7afFF161E7152Bec4272B51Cc007E4417AE',
      80001: '',
    },
	masterChefAddress:{
		137: '0xddeff79d2355999d5807c143b0987cdb6fd31edd',
		80001: '',
	},
	spid: 0,
    token: tokens.gfi,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:18.59,
	platform: 'Gravity',
  },
  {
    pid: 87,
    lpSymbol: 'USDC - USDT',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0x8563478c837BA6e7C4fEA1E53F88F34fdB25a803',
		80001: '',
	},
    lpAddresses: {
      137: '0x42286296c3ede3f6a0ec4e687939b017408cf321',
      80001: '',
    },
	masterChefAddress:{
		137: '0xe3bC11531D78Ce351Db9D2f0eC270B863FaC1C07',
		80001: '',
	},
	spid: 0,
    token: tokens.usdt,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0.88,
	platform: 'Gravity',
  }  
, 
{
    pid: 90,
    lpSymbol: 'GFI - WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/ETH/0x874e178a2f3f3f9d34db862453cd756e7eab0381',
	strategyAddress:{
		137: '0x7BBb79B98798b3d8E0db1657d4DE909ad69152a7',
		80001: '',
	},
    lpAddresses: {
      137: '0x2aabda501253b3aeeb4bee0bf7b0d5fd87b038eb',
      80001: '',
    },
	masterChefAddress:{
		137: '0x73933555527Ad78e1d2B318334c843D13BbDA9F8',
		80001: '',
	},
	spid: 0,
    token: tokens.gfi,
    quoteToken: tokens.weth,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:11.08,
	platform: 'Gravity',
  },
  {
    pid: 91,
    lpSymbol: 'GFI - MATIC',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/0x874e178a2f3f3f9d34db862453cd756e7eab0381',
	strategyAddress:{
		137: '0x1fF7293614936f8De6D8822b4568Be0A398A2014',
		80001: '',
	},
    lpAddresses: {
      137: '0xf08c4ebae711242ca26c6c29116badbc9af123ee',
      80001: '',
    },
	masterChefAddress:{
		137: '0x532AbbC6468c39A2933AD553AD0E581731F243ff',
		80001: '',
	},
	spid: 0,
    token: tokens.gfi,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:12.82,
	platform: 'Gravity',
  } ,
  {
    pid: 92,
    lpSymbol: 'USDC - WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
	strategyAddress:{
		137: '0xbFDDb2173f16b76b80C9936BF35efFFA51EFDfB7',
		80001: '',
	},
    lpAddresses: {
      137: '0xeefe518d89a49328580480fa3d90073bda074d32',
      80001: '',
    },
	masterChefAddress:{
		137: '0xbc3205826d5924AcB809FD0D3e7B14A1de504744',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:7.4,
	platform: 'Gravity',
  }
  ,
  {
    pid: 93,
    lpSymbol: 'DAI - WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
	strategyAddress:{
		137: '0x466Cd06a3847DFc8c20094CBc2596F9E02bD7b2d',
		80001: '',
	},
    lpAddresses: {
      137: '0x73a7b621090b8d7584cc40d1b1f016fb831949a3',
      80001: '',
    },
	masterChefAddress:{
		137: '0x253b345A9fa785C929867E6Db9e4b3C72e1c85c8',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.dai,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:6.2,
	platform: 'Gravity',
  }
  ,
  {
    pid: 94,
    lpSymbol: 'WMATIC - USDC',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/ETH/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	strategyAddress:{
		137: '0x3e24d43df4bfE88C659838eC0791d5696e5F7C21',
		80001: '',
	},
    lpAddresses: {
      137: '0xe0ce1d5380681d0d944b91c5a56d2b56e3cc93dc',
      80001: '',
    },
	masterChefAddress:{
		137: '0xfe187f250Acd76319c0f825007e34533723DD5eC',
		80001: '',
	},
	spid: 0,
    token: tokens.wmatic,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:20.03,
	platform: 'Gravity',
  }
    ,
  {
    pid: 95,
    lpSymbol: 'AAVE - WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xd6df932a45c0f255f85145f286ea0b292b21c90b',
	strategyAddress:{
		137: '0x83915735E1bAc1F535aa481a81Df61C6138D3407',
		80001: '',
	},
    lpAddresses: {
      137: '0x1d7c760a79ec798cbc5f6e017baaa445965daba8',
      80001: '',
    },
	masterChefAddress:{
		137: '0x4ceBD96D384f7B00A462876cc50BaE8F50D0cd1A',
		80001: '',
	},
	spid: 0,
    token: tokens.aave,
    quoteToken: tokens.weth,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:2.89,
	platform: 'Gravity',
  }
    ,
  {
    pid: 96,
    lpSymbol: 'LINK-WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xCcb255C4C77bd31B205f384333062c1B6a7D9964',
		80001: '',
	},
    lpAddresses: {
      137: '0x45ea5ce4e764ae027b64c8df8f457e168964e026',
      80001: '',
    },
	masterChefAddress:{
		137: '0x7C870fE594cF31775bE218263242382F94d28535',
		80001: '',
	},
	spid: 0,
    token: tokens.link,
    quoteToken: tokens.weth,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:4.31,
	platform: 'Gravity',
  }
     ,
  {
    pid: 97,
    lpSymbol: 'CRV-WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x172370d5cd63279efa6d502dab29171933a610af/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0x02701A2Ec165F953C627F763664722e66F6eB54D',
		80001: '',
	},
    lpAddresses: {
      137: '0xce4eb53cdf8bbc07c8dcc2f7a39984cc929af15d',
      80001: '',
    },
	masterChefAddress:{
		137: '0x76EBe94B0a5903003438027a2075c5FdFB8353a9',
		80001: '',
	},
	spid: 0,
    token: tokens.crv,
    quoteToken: tokens.weth,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:22,
	platform: 'Gravity',
  },
  /* {
    pid: 98,
    lpSymbol: 'SUSHI',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://app.sushi.com/swap?outputCurrency=0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
	strategyAddress:{
		137: '0xE38e6F238F9AbD7BE810a36d1906c1AE66AE178E',
		80001: '',
	},
    lpAddresses: {
      137: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0Dbe8999Cde32164340411897a7DD73654F82571',
		80001: '',
	},
	spid: 0,
    token: tokens.sushi,
    quoteToken: tokens.sushi,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:true,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0,
	platform: 'Gravity',
  }, */
  {
    pid: 99,
    lpSymbol: 'LINK',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/swap?outputCurrency=0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
	strategyAddress:{
		137: '0xe250F795E45f666C273d2634409FcD14e28B5dc5',
		80001: '',
	},
    lpAddresses: {
      137: '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39',
      80001: '',
    },
	masterChefAddress:{
		137: '0x2b1966652Aa0c09a2f34cE3FbeB19d945dEB8FA3',
		80001: '',
	},
	spid: 0,
    token: tokens.link,
    quoteToken: tokens.link,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:true,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0,
	platform: 'Gravity',
  },
  {
    pid: 100,
    lpSymbol: 'MATIC - WETH',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/ETH/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xC74723080AA347D6089b06B3c4345AaE9350dfa2',
		80001: '',
	},
    lpAddresses: {
      137: '0x0dfbf1a50bdcb570bd0ff7bb307313b553a02598',
      80001: '',
    },
	masterChefAddress:{
		137: '0xCFaf1e3fabF1fAE980258Fc643fBA9Fe1fB44655',
		80001: '',
	},
	spid: 0,
    token: tokens.weth,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:11.53,
	platform: 'Gravity',
  },  
  {
    pid: 105,
    lpSymbol: 'CRYSTL - MATIC',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x76bf0c28e604cc3fe9967c83b3c3f31c213cfe64',
	strategyAddress:{
		137: '0x05b6b45dbf24f7ecf4af38c34b3690b1e3e7b7ea',
		80001: '',
	},
    lpAddresses: {
      137: '0xb8e54c9ea1616beebe11505a419dd8df1000e02a',
      80001: '',
    },
	masterChefAddress:{
		137: '0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
		80001: '',
	},
	spid: 1,
    token: tokens.crystl,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.crystl,
	emissionFunctionName: 'crystalPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:19.84,
	platform: 'Crystal',
  },
   {
    pid: 107,
    lpSymbol: 'SX - MATIC',  
	exchange: 'Ape LP',
	baseLiquidityUrl: 'https://app.apeswap.finance/add/ETH/0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
	strategyAddress:{
		137: '0x25dd74838b00dff35bbccfc0770ce532c37ec902',
		80001: '',
	},
    lpAddresses: {
      137: '0xF90331CB67f9aE85739F5AEDFA93dEC68B7cE32e',
      80001: '',
    },
	masterChefAddress:{
		137: '0xebcc84d2a73f0c9e23066089c6c24f4629ef1e6d',
		80001: '',
	},
	spid: 52,
    token: tokens.sx,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.crystl,
	emissionFunctionName: 'crystalPerBlock',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:1.75,
	platform: 'Crystal',
  },
  {
    pid: 108,
    lpSymbol: 'WUSD - USDC',  
	exchange: 'Wault LP',
	baseLiquidityUrl: 'https://swap.wault.finance/polygon/index.html#/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0xb8ab048d6744a276b2772dc81e406a4b769a5c3d',
	strategyAddress:{
		137: '0xC4541fd09A8187F813B21F6769078FB77f8A2130',
		80001: '',
	},
    lpAddresses: {
      137: '0x7B93196CD4861f345509c2da31058CA4B1c30D63',
      80001: '',
    },
	masterChefAddress:{
		137: '0xC8Bd86E5a132Ac0bf10134e270De06A8Ba317BFe',
		80001: '',
	},
	spid: 28,
    token: tokens.wusd,
    quoteToken: tokens.usdc,
	rewardToken: tokens.wexpoly,
	emissionFunctionName: 'wexPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'lastRewardTime',
	lpRewardsApr:7.31,
	platform: 'Wault',
  },
  {
    pid: 110,
    lpSymbol: 'ELON - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xe0339c80ffde91f3e20494df88d4206d86024cdf',
	strategyAddress:{
		137: '0x73B4D5b11b006c81fc1503470e11d9c990a8D51c',
		80001: '',
	},
    lpAddresses: {
      137: '0x13305f843e66f7cc7f9cb1bbc40dabee7086d1f8',
      80001: '',
    },
	masterChefAddress:{
		137: '0x79a8337f65127a2d1df164ae23065f39102a1a5f',
		80001: '',
	},
	spid: 0,
    token: tokens.elon,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:38.48,
	platform: 'Quickswap',
  },
  {
    pid: 111,
    lpSymbol: 'renDOGE - ETH',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/0xce829a89d4a55a63418bcc43f00145adef0edb8e',
	strategyAddress:{
		137: '0xdC54fE54d8612b6cAa9F897975452D150Ef651C1',
		80001: '',
	},
    lpAddresses: {
      137: '0xab1403de66519b898b38028357b74df394a54a37',
      80001: '',
    },
	masterChefAddress:{
		137: '0x16043947b496a5B31932bcF9f41dD66880ff2Bb7',
		80001: '',
	},
	spid: 0,
    token: tokens.rendoge,
    quoteToken: tokens.weth,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRate',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	allocPointName: 'lastRewardTime',
	lpRewardsApr:7.75,
	platform: 'Quickswap',
  },
  {
    pid: 112,
    lpSymbol: 'MATIC - USDC',  
	exchange: 'Wault LP',
	baseLiquidityUrl: 'https://swap.wault.finance/polygon/index.html#/add/MATIC/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	strategyAddress:{
		137: '0xEe8f46f963614D3be4781563218Ba2a76851DA17',
		80001: '',
	},
    lpAddresses: {
      137: '0x8312A29A91D9Fac706F4d2ADEB1FA4540faD1673',
      80001: '',
    },
	masterChefAddress:{
		137: '0xC8Bd86E5a132Ac0bf10134e270De06A8Ba317BFe',
		80001: '',
	},
	spid: 5,
    token: tokens.wmatic,
    quoteToken: tokens.usdc,
	rewardToken: tokens.wexpoly,
	emissionFunctionName: 'wexPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:87.1,
	platform: 'Wault',
  },
  {
    pid: 113,
    lpSymbol: 'MATIC - ETH',  
	exchange: 'Wault LP',
	baseLiquidityUrl: 'https://swap.wault.finance/polygon/index.html#/add/MATIC/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
	strategyAddress:{
		137: '0xa0A97eDC69F805492C713FC4988062B02166B548',
		80001: '',
	},
    lpAddresses: {
      137: '0x679B8Ab80F298bF802fB294137765C6386D43dcA',
      80001: '',
    },
	masterChefAddress:{
		137: '0xC8Bd86E5a132Ac0bf10134e270De06A8Ba317BFe',
		80001: '',
	},
	spid: 4,
    token: tokens.wmatic,
    quoteToken: tokens.weth,
	rewardToken: tokens.wexpoly,
	emissionFunctionName: 'wexPerBlock',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:36.58,
	platform: 'Wault',
  },
  {
    pid: 114,
    lpSymbol: 'MATIC - USDC',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
	strategyAddress:{
		137: '0xaff2bc43976d990871e9698ff558fea8e005e562',
		80001: '',
	},
    lpAddresses: {
      137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
      80001: '',
    },
	masterChefAddress:{
		137: '0x14977e7e263ff79c4c3159f497d9551fbe769625',
		80001: '',
	},
	spid: 0,
    token: tokens.usdc,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:21.28,
	platform: 'Quickswap',
	rewarder:'0x14977e7e263ff79c4c3159f497d9551fbe769625',
  },
  {
    pid: 115,
    lpSymbol: 'MATIC - USDT',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
	strategyAddress:{
		137: '0xc8eb6200b62f04b7a82a1116f830e58cd366da46',
		80001: '',
	},
    lpAddresses: {
      137: '0x604229c960e5cacf2aaeac8be68ac07ba9df81c3',
      80001: '',
    },
	masterChefAddress:{
		137: '0xc0eb5d1316b835f4b584b59f922d9c87ca5053e5',
		80001: '',
	},
	spid: 0,
    token: tokens.usdt,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:22.1,
	platform: 'Quickswap',
	rewarder:'0xc0eb5d1316b835f4b584b59f922d9c87ca5053e5',
  },
  {
    pid: 116,
    lpSymbol: 'MATIC - QUICK',  
	exchange: 'Quick LP',
	baseLiquidityUrl: 'https://quickswap.exchange/#/ETH/0x831753dd7087cac61ab5644b308642cc1c33dc13',
	strategyAddress:{
		137: '0x2d62f85eb23d19e206343c5ab48818358130f5ad',
		80001: '',
	},
    lpAddresses: {
      137: '0x019ba0325f1988213d448b3472fa1cf8d07618d7',
      80001: '',
    },
	masterChefAddress:{
		137: '0xd26E16f5a9dfb9Fe32dB7F6386402B8AAe1a5dd7',
		80001: '',
	},
	spid: 0,
    token: tokens.quick,
    quoteToken: tokens.wmatic,
	rewardToken: tokens.quick,
	emissionFunctionName: 'rewardRateA',
	emissionMultiplier: 2,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: false,
	allocPointName:'lastRewardTime',
	lpRewardsApr:15.97,
	platform: 'Quickswap',
	rewarder:'0xd26E16f5a9dfb9Fe32dB7F6386402B8AAe1a5dd7',
  },
  {
    pid: 121,
    lpSymbol: 'SUSHI',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://app.sushi.com/swap?outputCurrency=0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
	strategyAddress:{
		137: '0xa12662e982646b4e076fa6d2213d65d1a87977e0',
		80001: '',
	},
    lpAddresses: {
      137: '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a',
      80001: '',
    },
	masterChefAddress:{
		137: '0x0Dbe8999Cde32164340411897a7DD73654F82571',
		80001: '',
	},
	spid: 0,
    token: tokens.sushi,
    quoteToken: tokens.sushi,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: true,
	isSingle:true,
	isBurning:false,
	isStable: false,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0,
	platform: 'Gravity',
  },
  {
    pid: 88,
    lpSymbol: 'USDC - DAI',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
	strategyAddress:{
		137: '0xc8A66a7587e4BcFDd4C23662C7924D1af17856d1',
		80001: '',
	},
    lpAddresses: {
      137: '0x4bb452b8b74864f44913f100bee280daa61ac298',
      80001: '',
    },
	masterChefAddress:{
		137: '0x5F6db84481A08B373BA7a0Ac4e0a13012B2Cd05f',
		80001: '',
	},
	spid: 0,
    token: tokens.dai,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0.72,
	platform: 'Gravity',
  },
  {
    pid: 89,
    lpSymbol: 'UST - USDC',  
	exchange: 'Gravity LP',
	baseLiquidityUrl: 'https://gravityfinance.io/add/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/0x692597b009d13c4049a947cab2239b7d6517875f',
	strategyAddress:{
		137: '0xc0EF7fFf5318BBf9AB49Ea25151cce2f5aA23bAe',
		80001: '',
	},
    lpAddresses: {
      137: '0xe507a93b99120d916229ee6d4adb4204bbb04edc',
      80001: '',
    },
	masterChefAddress:{
		137: '0xFa51C6CBe06E1Ec6eb9D1E72bd315067fC977dFF',
		80001: '',
	},
	spid: 0,
    token: tokens.ust,
    quoteToken: tokens.usdc,
	rewardToken: tokens.gfi,
	emissionFunctionName: 'farmInfo',
	emissionMultiplier: 1,
	isArchived: false,
	isSingle:false,
	isBurning:false,
	isStable: true,
	allocPointName:'totalAmountStaked',
	lpRewardsApr:0.98,
	platform: 'Gravity',
  },
]

export default vaults
